import { render, staticRenderFns } from "./add-sender.vue?vue&type=template&id=7ee1c8f2&scoped=true"
import script from "./add-sender.ts?vue&type=script&lang=ts&external"
export * from "./add-sender.ts?vue&type=script&lang=ts&external"
import style0 from "./add-sender.scoped.scss?vue&type=style&index=0&id=7ee1c8f2&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ee1c8f2",
  null
  
)

export default component.exports